<template>
  <div>
    <b-field label="分類" :horizontal="horizontal">
      <b-field grouped>
        <b-select v-model="parentGroupIdAlt" :disabled="disabled">
          <option :value="null">未選択</option>
          <option
            v-for="group in parentGroups"
            :value="group.id"
            :key="group.id"
          >
            {{ group.name }}
          </option>
        </b-select>
        <b-select v-model="groupIdAlt" :disabled="disabled || !parentGroupId">
          <option :value="null">未選択</option>
          <option
            v-for="group in groupOptions"
            :value="group.id"
            :key="group.id"
          >
            {{ group.name }}
          </option>
        </b-select>
      </b-field>
    </b-field>
    <b-field label="プログラム" :horizontal="horizontal">
      <ValidationField rules="required">
        <b-select v-model="valueAlt" icon="bookmark" :disabled="disabled || !groupId">
          <option :value="null">未選択</option>
          <option
            v-for="mProgram in filterMPrograms"
            :value="mProgram.id"
            :key="mProgram.id"
          >
            {{ mProgram.name }}
          </option>
        </b-select>
      </ValidationField>
    </b-field>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { MProgram } from '@/types/typeProgram'
import { TypeEnum } from '@/types/enum'
export default {
  props: {
    horizontal: {
      type: Boolean,
      default: false
    },
    value: {
      type: MProgram,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    facility: {
      type: TypeEnum,
      default: null,
    },
    area: {
      type: TypeEnum,
      default: null,
    },
  },
  computed: {
    ...mapState('storeProgram', ['mProgramGroups', 'mPrograms']),
    parentGroups () {
      const base = this.mProgramGroups.filter(row => !row.parentId)
      if (!this.facility) {
        return base
      }
      return base.filter(row => row.poolFlag === this.facility.options.poolFlag)
    },
    parentGroupIdAlt: {
      get () {
        return this.parentGroupId
      },
      set (val) {
        this.parentGroupId = val
        this.groupId = null
        this.$emit('input', null)
      }
    },
    groupOptions () {
      if (!this.parentGroupId) {
        return []
      }
      return this.mProgramGroups.filter(row => row.parentId === this.parentGroupId)
    },
    groupIdAlt: {
      get () {
        return this.groupId
      },
      set (val) {
        this.groupId = val
        if (this.filterMPrograms.length === 1) {
          this.$emit('input', this.filterMPrograms[0])
        } else {
          this.$emit('input', null)
        }
      }
    },
    filterMPrograms () {
      return this.mPrograms.filter(mProgram => {
        if (this.parentGroupId && mProgram.parentGroupId !== this.parentGroupId) {
          return false
        }
        if (this.groupId && mProgram.groupId !== this.groupId) {
          return false
        }
        if (this.defaultId && mProgram.id === this.defaultId) {
          return true
        }
        if (this.area && !mProgram.areas.includes(this.area.value)) {
          return false
        }
        return mProgram.active
      })
    },
    valueAlt: {
      get () {
        return this.value ? this.value.id : null
      },
      set (val) {
        const mProgram = this.mPrograms.find(row => row.id === val)
        this.$emit('input', mProgram || null)
      }
    }
  },
  data () {
    if (this.value) {
      return {
        defaultId: this.value.id,
        groupId: this.value.groupId,
        parentGroupId: this.value.parentGroupId
      }
    }
    return {
      defaultId: null,
      parentGroupId: null,
      groupId: null
    }
  },
  watch: {
    value (newValue) {
      if (newValue) {
        this.parentGroupId = newValue.parentGroupId
        this.groupId = newValue.groupId
      }
    },
    facility (newValue, oldValue) {
      if (newValue && oldValue && newValue.options.poolFlag === oldValue.options.poolFlag) {
        return
      }
      this.parentGroupId = null
      this.groupId = null
      this.$emit('input', null)
    }
  },
}
</script>
