<template>
  <b-table
    :loading="loading"
    :paginated="paginated"
    :per-page="perPage"
    narrowed
    :striped="true"
    :default-sort="defaultSort"
    :data="rows"
    :row-class="(row, index) => row.rowClass"
  >
    <b-table-column
      label="管理番号"
      width="8em"
      field="code"
      sortable
      v-slot="{ row }"
    >
      {{ row.code }}
    </b-table-column>
    <b-table-column
      label="氏名"
      width="10em"
      field="kana"
      sortable
      v-slot="{ row }"
    >
      <InstructorLabel :mInstructor="row"
        position="is-right" /><br />{{ row.kana }}
    </b-table-column>
    <b-table-column
      label="実施確認"
      width="6em"
      field="checkable"
      sortable
      centered
      v-slot="{ row }"
    >
      <b-tag v-if="row.checkable" type="is-light">ON</b-tag>
      <b-tag v-else type="is-dark">OFF</b-tag>
    </b-table-column>
    <b-table-column :label="FeeCategory.Jr.label" width="6em" numeric field="feeJr" v-slot="{ row }">
      {{ row.feeJr ? `${row.feeJr.toLocaleString()}円` : '-' }}
    </b-table-column>
    <b-table-column :label="FeeCategory.Yoga.label" width="6em" numeric field="feeYoga" v-slot="{ row }">
      {{ row.feeYoga ? `${row.feeYoga.toLocaleString()}円` : '-' }}
    </b-table-column>
    <b-table-column :label="FeeCategory.Aqua.label" width="6em" numeric field="feeAqua" v-slot="{ row }">
      {{ row.feeAqua ? `${row.feeAqua.toLocaleString()}円` : '-' }}
    </b-table-column>
    <b-table-column
    :label="FeeCategory.Other.label"
      width="6em"
      field="feeOther"
      numeric
      v-slot="{ row }"
    >
      {{ row.feeOther ? `${row.feeOther.toLocaleString()}円` : '-' }}
    </b-table-column>
    <b-table-column
    :label="FeeCategory.Etc1.label"
      width="6em"
      field="feeEtc1"
      numeric
      v-slot="{ row }"
    >
      {{ row.feeEtc1 ? `${row.feeEtc1.toLocaleString()}円` : '-' }}
    </b-table-column>
    <b-table-column
    :label="FeeCategory.Etc2.label"
      width="6em"
      field="feeEtc2"
      numeric
      v-slot="{ row }"
    >
      {{ row.feeEtc2 ? `${row.feeEtc2.toLocaleString()}円` : '-' }}
    </b-table-column>
    <b-table-column
      label=""
      width="4em"
      field="activeSortVal"
      sortable
      v-slot="{ row }"
    >
      <b-tag v-if="row.active" type="is-light">有効</b-tag>
      <b-tag v-else type="is-dark">無効</b-tag>
      <a href="#" @click.prevent.stop="$emit('willToFeeSpecial', row)">
        <b-tag v-if="row.feeSpecialCount > 0" type="is-warning">特例</b-tag>
      </a>
    </b-table-column>
    <b-table-column label="備考" field="note" width="3em" sortable centered v-slot="{ row }">
      <b-tooltip v-if="row.note" :label="row.note" type="is-dark">
        <b-icon icon="comment-outline" />
      </b-tooltip>
      <span v-else />
    </b-table-column>
    <b-table-column
      custom-key="actions"
      cell-class="is-actions-cell"
      v-slot="{ row }"
    >
      <div class="buttons is-right">
        <b-button
          type="is-info"
          size="is-small"
          icon-left="dock-window"
          @click="$emit('willToFees', row)"
          >フィー設定</b-button
        >
        <b-button
          type="is-warning"
          size="is-small"
          icon-left="square-edit-outline"
          @click="$emit('willEdit', row)"
          >基本設定</b-button
        >
      </div>
    </b-table-column>

    <section class="section" slot="empty">
      <div class="content has-text-grey has-text-centered">
        <template v-if="loading">
          <p>
            <b-icon icon="dots-horizontal" size="is-large" />
          </p>
          <p>Fetching data...</p>
        </template>
        <template v-else>
          <p>
            <b-icon icon="emoticon-sad" size="is-large" />
          </p>
          <p>Nothing's here&hellip;</p>
        </template>
      </div>
    </section>
  </b-table>
</template>

<script>
import { InstructorTableFilter } from '@/types/typeInstructor'
import { Enums } from '@/types/enum'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    mInstructors: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: InstructorTableFilter,
      default: null
    },
  },
  data () {
    return {
      perPage: 10,
      defaultSort: 'code'
    }
  },
  computed: {
    FeeCategory: () => Enums.FeeCategory,
    paginated () {
      return this.rows.length > this.perPage
    },
    rows () {
      if (!this.filter) {
        return this.mInstructors
      }
      return this.mInstructors.filter(row => this.filter.test(row))
    },
  },
}
</script>
