<template>
  <div class="block" style="min-height: 300px">
    <b-field>
      <div class="control">
        <b-switch type="is-info" v-model="showNew">設定を追加する</b-switch>
      </div>
    </b-field>
    <div v-if="showNew">
      <FeeCategoryRowForm @save="didSaveRow" />
      <hr />
    </div>

    <b-table
      :loading="loading"
      :paginated="paginated"
      :per-page="perPage"
      detailed
      detail-key="key"
      :data="feeCategoryForms"
      :has-detailed-visible="(row) => true"
      ref="btable"
    >
      <b-table-column label="金額" v-slot="{ row }">
        {{ row.amount.toLocaleString() }}円
      </b-table-column>
      <b-table-column label="適用開始日" v-slot="{ row }">
        {{ row.startDate.format('YYYY-MM-DD') }}
      </b-table-column>

      <template #detail="{ row }">
        <FeeCategoryRowForm
          :feeForm="row"
          @save="didSaveRow"
          @remove="willRemove"
        />
      </template>

      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="loading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import { TypeEnum } from '@/types/enum'
import { FeeForm } from '@/types/typeInstructor'
import FeeCategoryRowForm from './FeeCategoryRowForm.vue'
export default {
  components: { FeeCategoryRowForm },
  props: {
    category: {
      type: TypeEnum
    },
    loading: Boolean,
    feeForms: {
      type: Array
    }
  },
  computed: {
    feeCategoryForms () {
      return this.feeForms.filter(feeForm => feeForm.isCategory && feeForm.category === this.category)
    },
    paginated () {
      return this.feeCategoryForms.length > this.perPage
    },
  },
  data () {
    return {
      showNew: false,
      perPage: 4,
    }
  },
  methods: {
    didSaveRow ({ data, feeForm }) {
      if (feeForm) {
        // 既存反映
        this.$refs.btable.closeDetailRow(feeForm)
        feeForm.reflectCategory(data)
        this.$emit('edit', feeForm)
      } else {
        // 新規反映
        this.showNew = false
        const feeForm = new FeeForm().setCategory(this.category).reflectCategory(data)
        this.$emit('create', feeForm)
      }
    },
    willRemove (feeForm) {
      this.$emit('remove', feeForm)
    }
  },
  mounted () {
  }
}
</script>
