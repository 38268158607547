<template>
  <div>
    <TitleBar :title-stack="titleStack" />
    <HeroBar>
      インストラクター管理
      <div class="buttons" slot="right">
        <b-button
          icon-left="plus-circle"
          type="is-info"
          size="is-small"
          @click="willCreate"
          >新規作成</b-button
        >
      </div>
    </HeroBar>
    <section class="section is-main-section">
      <CardComponent
        class="has-table has-mobile-sort-spaced"
        title="インストラクター一覧"
        icon="human"
      >
        <refresh-button slot="button" />
        <CardToolbar slot="toolbar">
          <div class="block" slot="left">
            <div class="field has-addons">
              <b-input v-model="filter.text" placeholder="あいまい検索" />
              <div class="control">
                <b-button type="is-light" @click="filter.text = ''"
                  ><b-icon icon="eraser" custom-size="default"
                /></b-button>
              </div>
            </div>
          </div>
          <div class="block" slot="right">
            <b-field grouped>
              <b-field label="実施確認">
                <b-checkbox v-model="filter.showCheckable">ON</b-checkbox>
                <b-checkbox v-model="filter.showUnCheckable">OFF</b-checkbox>
              </b-field>
              <b-field label="ステータス">
                <b-checkbox v-model="filter.showActive">有効</b-checkbox>
                <b-checkbox v-model="filter.showDeActive">無効</b-checkbox>
              </b-field>
            </b-field>
          </div>
        </CardToolbar>
        <InstructorsTable
          :loading="isLoading"
          :mInstructors="mInstructors"
          :filter="filter"
          @willEdit="willEdit"
          @willToFees="willToFees"
          @willToFeeSpecial="willToFeeSpecial"
        />
      </CardComponent>

      <Notification class="is-info" v-if="false">
        <div>
          <b-icon icon="buffer" custom-size="default" />
          <b>Sorted and paginated table.</b>&nbsp;Based on Buefy's table.
        </div>
      </Notification>
    </section>
  </div>
</template>

<script>
import BaseMixin from '@/baseMixin'
import Notification from '@/components/Notification.vue'
import CardComponent from '@/components/CardComponent.vue'
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import RefreshButton from '@/components/RefreshButton.vue'
import CardToolbar from '@/components/CardToolbar.vue'
import { ApiInstructor } from '@/api'
import InstructorsTable from '@/components/Instructors/InstructorsTable.vue'
import { InstructorTableFilter } from '@/types/typeInstructor'
import InstructorFormVue from '@/components/Instructors/InstructorForm.vue'
import FeesFormVue from '@/components/Instructors/FeesForm.vue'
import { Collection } from '@/util'
import { mapActions } from 'vuex'
export default {
  mixins: [BaseMixin],
  components: { CardToolbar, RefreshButton, HeroBar, TitleBar, CardComponent, Notification, InstructorsTable },
  computed: {
    titleStack () {
      return [
        'マスタ管理', 'インストラクター'
      ]
    }
  },
  data () {
    return {
      mInstructors: [],
      filter: new InstructorTableFilter(),
    }
  },
  methods: {
    ...mapActions('storeProgram', {
      forgeProgramStates: 'forgeStates'
    }),
    willCreate () {
      console.log('willCreate')
      this.$buefy.modal.open({
        parent: this,
        component: InstructorFormVue,
        hasModalCard: true,
        canCancel: false,
        // trapFocus: true,
        events: {
          saved: (mInstructor) => {
            console.log(mInstructor, 'didCreate')
            mInstructor.rowClass = 'is-created'
            this.mInstructors = new Collection(this.mInstructors).setProp('rowClass', null).set(mInstructor).members
            this.alertSuccess('インストラクターを作成しました')
          }
        }
      })
    },
    willEdit (mInstructor) {
      console.log(mInstructor, 'willEdit')
      this.$buefy.modal.open({
        parent: this,
        component: InstructorFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: { mInstructor },
        events: {
          saved: (mInstructor) => {
            mInstructor.rowClass = 'is-updated'
            this.mInstructors = new Collection(this.mInstructors).setProp('rowClass', null).set(mInstructor).members
            this.alertSuccess('インストラクターを更新しました')
          },
          reseted: (mInstructor) => {
            new Collection(this.mInstructors).setProp('rowClass', null)
            mInstructor.rowClass = 'is-updated'
            this.alertSuccess('パスワードをリセットしました')
          },
          deleted: (deletedId) => {
            this.mInstructors = new Collection(this.mInstructors).setProp('rowClass', null).remove(deletedId).members
            this.alertSuccess('インストラクターを削除しました')
          }
        }
      })
    },
    willToFeeSpecial (mInstructor) {
      console.log(mInstructor, 'willToFeeSpecial')
      this.toFees(mInstructor, true)
    },
    willToFees (mInstructor) {
      this.toFees(mInstructor, false)
    },
    toFees (mInstructor, toSpecial) {
      console.log(mInstructor, 'willToFees')
      this.$buefy.modal.open({
        parent: this,
        component: FeesFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: { mInstructor, toSpecial },
        events: {
          saved: (mInstructor) => {
            mInstructor.rowClass = 'is-updated'
            this.mInstructors = new Collection(this.mInstructors).setProp('rowClass', null).set(mInstructor).members
            this.alertSuccess('フィー設定を更新しました')
          },
        }
      })
    },
  },
  created () {
    this.forgeProgramStates()
  },
  mounted () {
    this.isLoading = true
    ApiInstructor.fetchInstructors({ fee: 1 }).then(({ mInstructors }) => {
      this.mInstructors = mInstructors
      this.isLoading = false
    })
  }
}
</script>
