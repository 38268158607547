<template>
  <ValidationObserver v-slot="{ handleSubmit }" slim>
    <form>
      <b-field grouped>
        <ValidationInput
          type="number"
          v-model="amount"
          rules="required"
          placeholder="金額"
        ></ValidationInput>
        <ValidationField rules="required">
          <Datepicker
            v-model="startDate"
            placeholder="適用開始日"
            :position="pickPosition"
          />
        </ValidationField>
        <b-field label="" expanded>
          <b-button @click.prevent="handleSubmit(willSave)" type="is-info">{{
            buttonText
          }}</b-button>
        </b-field>
        <b-field label="" expanded v-if="!isCreate">
          <b-button type="is-danger" @click="willRemove" icon-left="delete" />
        </b-field>
      </b-field>
    </form>
  </ValidationObserver>
</template>

<script>
import { FeeForm } from '@/types/typeInstructor'
import dayjs from 'dayjs'
export default {
  props: {
    feeForm: {
      type: FeeForm,
      default: null
    }
  },
  computed: {
    isCreate () {
      return !this.feeForm
    },
    buttonText () {
      return this.isCreate ? '追加' : '反映'
    },
    pickPosition () {
      return this.isCreate ? 'is-bottom-right' : 'is-top-right'
    }
  },
  data () {
    return {
      amount: '',
      // startDate: dayjs().add(1, 'day'),
      startDate: dayjs(),
    }
  },
  methods: {
    willRemove () {
      console.log('willRemove')
      this.$emit('remove', this.feeForm)
    },
    willSave () {
      const data = { amount: this.amount, startDate: this.startDate }
      this.$emit('save', { data, feeForm: this.feeForm })
    }
  },
  mounted () {
    if (this.feeForm) {
      this.amount = this.feeForm.amount.toString()
      this.startDate = dayjs(this.feeForm.startDate)
    }
  }
}
</script>
