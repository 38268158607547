<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
    <CardComponent
      :isScrollable="true"
      :title="cardTitle"
      icon="ballot"
      style="width: 800px"
      headerIcon="close"
      hasBottomSlot
      @header-icon-click="$emit('close')"
    >
      <form>
        <b-field
          label="管理番号"
          message="ログインID／パスワード初期値として利用されます。半角英数字4文字以上必要です。"
          horizontal
        >
          <ValidationInput
            v-model="instructorForm.code"
            rules="required|alpha_num|min:4|max:10"
            :autofocus="isCreate"
          ></ValidationInput>
        </b-field>
        <b-field label="氏名" horizontal>
          <ValidationInput
            v-model="instructorForm.name"
            rules="required|max:10"
          ></ValidationInput>
        </b-field>
        <b-field label="かな" horizontal :message="kanaMessage">
          <ValidationInput
            v-model="instructorForm.kana"
            rules="required|max:100"
          ></ValidationInput>
        </b-field>
        <b-field
          label="表示名"
          horizontal
          message="スケジュール管理画面でのカレンダー表示,csv出力に利用されます"
        >
          <ValidationInput
            v-model="instructorForm.displayName"
            :placeholder="instructorForm.name"
            rules="max:10"
          ></ValidationInput>
        </b-field>
        <b-field
          label="実施確認"
          horizontal
          message="OFFにすると、実施確認画面で担当レッスンが非表示になります。"
        >
          <b-switch v-model="instructorForm.checkable">
            {{ instructorForm.checkable ? 'ON' : 'OFF' }}
          </b-switch>
        </b-field>
        <b-field label="備考" horizontal>
          <b-input
            type="textarea"
            v-model="instructorForm.note"
            maxlength="500"
            :rows="2"
          />
        </b-field>
        <hr />
        <b-field
          label="ステータス"
          horizontal
          v-if="!isCreate"
          message="OFFにすると、ログイン不可になります。またレッスン担当に設定できなくなります。"
        >
          <b-switch v-model="instructorForm.active"> 有効 </b-switch>
        </b-field>
        <b-field
          label="パスワード"
          horizontal
          message="パスワードを管理番号にリセットします"
          v-if="mInstructor"
        >
          <b-button
            size="is-small"
            type="is-warning"
            :loading="isLoading"
            @click="willResetPassword"
            >リセット</b-button
          >
        </b-field>
      </form>
      <div slot="bottom" class="level p-4">
        <div class="level-left">
          <div class="level-item buttons">
            <b-button
              native-type="button"
              type="is-primary"
              :loading="isLoading"
              @click.prevent="handleSubmit(willSave)"
              >登録</b-button
            >
            <b-button
              type="is-light"
              @click="$emit('close')"
              :loading="isLoading"
              >キャンセル</b-button
            >
          </div>
        </div>
        <div class="level-right" v-if="!isCreate">
          <div class="level-item buttons">
            <b-button
              native-type="button"
              type="is-danger"
              icon-right="delete"
              :loading="isLoading"
              @click.prevent="willDelete"
            />
          </div>
        </div>
      </div>
    </CardComponent>
  </ValidationObserver>
</template>

<script>
import BaseMixin from '@/baseMixin'
import CardComponent from '@/components/CardComponent.vue'
import { MInstructor, InstructorForm } from '@/types/typeInstructor'
import { ApiPassword, ApiInstructor } from '@/api'
import { toHanKana } from '@/util'
export default {
  mixins: [BaseMixin],
  components: { CardComponent },
  props: {
    mInstructor: {
      type: MInstructor,
      default: null
    }
  },
  computed: {
    cardTitle () {
      return this.mInstructor ? 'インストラクター編集' : 'インストラクター新規作成'
    },
    isCreate () {
      return !this.mInstructor
    },
    kanaMessage () {
      const hanKana = toHanKana(this.instructorForm.kana)
      return hanKana ? `半角カナ：${hanKana}` : '全角ひらがなで入力してください。PCA出力時には半角カナに変換されます。'
    }
  },
  data () {
    return {
      instructorForm: new InstructorForm()
    }
  },
  methods: {
    willSave () {
      this.isLoading = true
      setTimeout(() => {
        ApiInstructor.saveInstructor(this.instructorForm).then(({ mInstructor }) => {
          this.$emit('saved', mInstructor)
          this.$emit('close')
          this.isLoading = false
        }).catch(this.handleError)
      }, 500)
    },
    willResetPassword () {
      console.log('willResetPassword')
      if (!this.mInstructor) {
        return
      }
      this.isLoading = true
      setTimeout(() => {
        ApiPassword.resetPassword(this.mInstructor.id).then(() => {
          this.$emit('reseted', this.mInstructor)
          this.$emit('close')
          this.isLoading = false
        }).catch(this.handleError)
      }, 500)
    },
    willDelete () {
      console.log('willDelete')
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        confirmText: '削除する',
        cancelText: 'キャンセル',
        message: 'インストラクターを削除しますか？',
        onConfirm: () => {
          this.isLoading = true
          setTimeout(() => {
            ApiInstructor.deleteInstructor(this.mInstructor.id).then(() => {
              this.$emit('deleted', this.mInstructor.id)
              this.$emit('close')
              this.isLoading = false
            }).catch(this.handleError)
          }, 500)
        }
      })
    }
  },
  mounted () {
    if (this.mInstructor) {
      this.instructorForm.fill(this.mInstructor)
    }
  }
}
</script>
