<template>
  <CardComponent
      :isScrollable="true"
      :title="cardTitle"
      icon="currency-cny"
      class="modal-card"
      style="width: 1000px"
      headerIcon="close"
      hasFooterSlot
      @header-icon-click="$emit('close')"
    >
    <form @submit.prevent>
      <b-tabs v-model="activeTab">
        <b-tab-item
          v-for="(category, idx) in FeeCategories"
          :key="category.value"
        >
          <template #header>
            <span>
              {{ category.label }}
              <b-tag rounded> {{ categoryCounts[idx] }} </b-tag>
            </span>
          </template>
          <FeeCategoryForm
            :loading="isLoading"
            :category="category"
            :feeForms="feeForms"
            @create="addForm"
            @edit="sortForms"
            @remove="removeForm"
          />
        </b-tab-item>
        <b-tab-item
        >
          <template #header>
            <span>特例
              <b-tag rounded> {{specialCounts}} </b-tag>
            </span>
          </template>
          <FeeProgramForm
            :loading="isLoading"
            :feeForms="feeForms"
            @create="addForm"
            @edit="sortForms"
            @remove="removeForm"
          />
        </b-tab-item>
      </b-tabs>
    </form>
      <div slot="footer" class="buttons p-4">
        <b-button
          native-type="button"
          type="is-primary"
          :loading="isLoading"
          @click.prevent="willSave"
          >登録</b-button
        >
        <b-button
          type="is-primary is-outlined"
          @click="$emit('close')"
          :loading="isLoading"
          >キャンセル</b-button
        >
      </div>
  </CardComponent>
</template>

<script>
import BaseMixin from '@/baseMixin'
import CardComponent from '@/components/CardComponent.vue'
import { MInstructor, FeeForm } from '@/types/typeInstructor'
import { ApiFee } from '@/api'
import { Enums } from '@/types/enum'
import FeeCategoryForm from './FeeCategoryForm.vue'
import { Collection } from '@/util'
import { mapGetters } from 'vuex'
import FeeProgramForm from './FeeProgramForm.vue'
export default {
  mixins: [BaseMixin],
  components: { CardComponent, FeeCategoryForm, FeeProgramForm },
  props: {
    mInstructor: {
      type: MInstructor
    },
    toSpecial: Boolean
  },
  computed: {
    ...mapGetters('storeProgram', {
      findStoreProgram: 'findMProgram'
    }),
    FeeCategories: () => Enums.FeeCategory.list,
    cardTitle () {
      return `フィー設定 ${this.mInstructor.name} (${this.mInstructor.code})`
    },
    categoryCounts () {
      return this.FeeCategories.map(category => {
        return this.feeForms.filter(feeForm => feeForm.isCategory && feeForm.category === category).length
      })
    },
    specialCounts () {
      return this.feeForms.filter(feeForm => feeForm.isSpecial).length
    }
  },
  data () {
    return {
      activeTab: 0,
      feeForms: []
    }
  },
  methods: {
    sortForms () {
      this.feeForms = new Collection(this.feeForms).sort('startDateSortval', 'desc').members
    },
    addForm (feeForm) {
      this.feeForms.push(feeForm)
      this.sortForms()
    },
    removeForm (feeForm) {
      this.feeForms = this.feeForms.filter(row => row !== feeForm)
    },
    willSave () {
      this.isLoading = true
      setTimeout(() => {
        ApiFee.saveFees(this.mInstructor.id, this.feeForms).then(({ mInstructor }) => {
          this.$emit('saved', mInstructor)
          this.$emit('close')
          this.isLoading = false
        }).catch(this.handleError)
      }, 500)
    },
  },
  mounted () {
    this.isLoading = true
    ApiFee.fetchFees(this.mInstructor.id).then(({ tFees }) => {
      this.feeForms = tFees.map(tFee => {
        const feeForm = new FeeForm().fill(tFee)
        if (tFee.isSpecial) {
          const mProgram = this.findStoreProgram(tFee.programId)
          feeForm.setProgram(mProgram)
        } else {
          feeForm.setCategory(tFee.category)
        }
        return feeForm
      })
      this.sortForms()
      if (this.toSpecial) {
        this.activeTab = this.FeeCategories.length
      }
      this.isLoading = false
    })
  }
}
</script>
